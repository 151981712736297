


































































































































































































import {
  SortFields,
  SortType,
} from '@/app/infrastructures/misc/Constants/shuttleManagement'
import {
  IHeaderCell,
  IOptions,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/shuttleManagement'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PlusIcon from '../../assets/ics_f_plus_circle_white.vue'
import Button from '../../components/Button/index.vue'
import DataTableV2 from '../../components/DataTableV2/index.vue'
import PaginationNav from '../../components/PaginationNav/index.vue'
import TextInput from '../../components/TextInput/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import EmptyState from '../../components/EmptyState/EmptyState.vue'
import controller from '../../controllers/ShuttleController'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '../../components/LoadingOverlay/index.vue'
import { Shuttle } from '@/domain/entities/Shuttle'
import ModalConfirm from './components/Modals/ModalConfirm/index.vue'

@Component({
  components: {
    TextInput,
    Button,
    PlusIcon,
    DataTableV2,
    PaginationNav,
    AscendingIcon,
    EmptyState,
    LoadingOverlay,
    ModalConfirm,
  },
})
export default class ShuttleManagementList extends Vue {
  controller = controller

  enumSelectedSort = SortType
  enumSortFields = SortFields

  selectedSort: SortType | null = null
  tableData: ITableCell[][] = []

  headers = [
    this.headerCellMapper('No', '80px'),
    this.headerCellMapper('Shuttle ID', '150px'),
    this.headerCellMapper('Nama Shuttle', '180px'),
    this.headerCellMapper('Alamat Shuttle', '200px'),
    this.headerCellMapper('Link Google Maps Lokasi Shuttle', '225px'),
    this.headerCellMapper('PIC', '300px'),
    this.headerCellMapper('Atur', '303px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
  }

  isModalConfirmVisible = false
  deletedShuttleName = ''
  deletedShuttleId = ''

  created(): void {
    this.fetchList(true)
    this.controller.resetShuttleForm()
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      q: this.parameters.search,
      sortBy: this.selectedSort ? <string>this.selectedSort.split('-')[0] : '',
      sortDirection: this.selectedSort
        ? <string>this.selectedSort.split('-')[1].toUpperCase()
        : '',
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getShuttleList(this.params)
  }

  public onSearch = Utils.debounce((keyword: string) => {
    if (keyword.length === 0 || keyword.length >= 3) {
      this.fetchList(true)
    }
  }, 500)

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchList(true)
  }

  public onTableSort(sortType: SortFields): void {
    switch (sortType) {
      case SortFields.SHUTTLE_ID:
        if (this.selectedSort === SortType.SHUTTLE_ID_ASC) {
          this.selectedSort = SortType.SHUTTLE_ID_DESC
        } else if (this.selectedSort === SortType.SHUTTLE_ID_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.SHUTTLE_ID_ASC
        }
        break
      case SortFields.SHUTTLE_NAME:
        if (this.selectedSort === SortType.SHUTTLE_NAME_ASC) {
          this.selectedSort = SortType.SHUTTLE_NAME_DESC
        } else if (this.selectedSort === SortType.SHUTTLE_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.SHUTTLE_NAME_ASC
        }
        break
    }
    this.fetchList(true)
  }

  public onDeleteShuttle(): void {
    this.controller.deleteShuttle(<string>this.deletedShuttleId)
    this.isModalConfirmVisible = false
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        wordBreak: 'break-word',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.shuttleData')
  onShuttleDataChanged(data: Shuttle[]): void {
    this.tableData = data.map((shuttle, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1) + '.',
        '80px'
      ),
      this.tableCellMapper(<string>shuttle.shuttleId, '150px'),
      this.tableCellMapper(<string>shuttle.shuttleName || '-', '180px'),
      this.tableCellMapper(<string>shuttle.shuttleAddress || '-', '200px'),
      this.tableCellMapper(<string>shuttle.shuttleMapUrl || '-', '225px'),
      this.tableCellMapper(
        <string>(
          shuttle.shuttlePic
            ?.map(pic => `${pic.picName} (${pic.picPhoneNumber})`)
            .join(', ')
        ),
        '300px'
      ),
      this.tableCellMapper(
        [<string>shuttle.shuttleId, <string>shuttle.shuttleName],
        '303px'
      ),
    ])
  }

  @Watch('controller.isDeleteShuttleSuccess')
  onIsDeleteShuttleSuccessChanged(data: boolean): void {
    if (data) {
      this.fetchList(true)
      this.controller.setIsDeleteShuttleSuccess(false)
      this.deletedShuttleId = ''
      this.deletedShuttleName = ''
      this.isModalConfirmVisible = false
    }
  }
}
