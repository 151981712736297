function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"mb-8 flex flex-row justify-between items-center"},[_c('h1',{staticClass:"text-xl font-semibold text-black"},[_vm._v("Shuttle Management")]),_c('Button',{staticClass:"flex flex-row gap-2 text-sm font-semibold",attrs:{"routeTo":{
        name: 'ShuttleManagementCreate',
      }}},[_c('PlusIcon'),_vm._v(" Shuttle ")],1)],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/2 mb-2"},[_c('span',{staticClass:"text-sm font-semibold text-bgMatterhorn"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"data-testid":"shuttle-list__search-input","type":"search","placeholder":"Cari dari ID/Nama Shuttle, Alamat, Wilayah, Nama PIC, No. HP PIC"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"shuttle-management-table list-shuttle"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.tableData,"isLoading":_vm.controller.isLoading},scopedSlots:_vm._u([{key:"header-1",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-list__sort-shuttle-id"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.SHUTTLE_ID)}}},[_c('span',{staticClass:"w-18 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.SHUTTLE_ID_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.SHUTTLE_ID_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-2",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-list__sort-shuttle-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.SHUTTLE_NAME)}}},[_c('span',{staticClass:"w-28 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.SHUTTLE_NAME_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.SHUTTLE_NAME_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"3",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"line-clamp-2 text-sm text-gray-4",attrs:{"title":data.data.value}},[_vm._v(" "+_vm._s(data.data.value)+" ")])]}},{key:"4",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('a',{staticClass:"line-clamp-2 text-sm text-blue-600",attrs:{"href":data.data.value,"target":"_blank"}},[_vm._v(_vm._s(data.data.value))])]}},{key:"5",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"text-sm text-gray-4 break-word"},[_vm._v(" "+_vm._s(data.data.value)+" ")])]}},{key:"6",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5"},[_c('router-link',{staticClass:"text-sm font-semibold text-bgPrimary",attrs:{"to":{
              name: 'ShuttleManagementEditForm',
              params: {
                shuttleId: data.data.value[0],
              },
            }}},[_vm._v(" Edit ")]),_c('router-link',{staticClass:"text-sm font-semibold text-bgPrimary",attrs:{"to":{
              name: 'ShuttleManagementDetail',
              params: {
                shuttleId: data.data.value[0],
              },
            }}},[_vm._v(" Lihat Detail ")]),_c('div',{staticClass:"text-sm font-semibold text-bgPrimary cursor-pointer",attrs:{"data-testid":("shuttle-list__delete-shuttle-" + (data.index))},on:{"click":function () {
                _vm.isModalConfirmVisible = true
                _vm.deletedShuttleName = data.data.value[1]
                _vm.deletedShuttleId = data.data.value[0]
              }}},[_vm._v(" Hapus ")])],1)]}},{key:"data-empty",fn:function(){return [_c('EmptyState',{staticClass:"mx-auto py-6",attrs:{"text":"Belum Ada Shuttle","description":"Yuk! tambahkan shuttle dengan klik Tambah Shuttle","classText":"text-bgMatterhorn text-sm font-semibold","classDesc":"text-gray-1 text-sm"}})]},proxy:true}])})],1),_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"data-testid":"shuttle-list__pagination","page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
        _vm.fetchList()
      },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('ModalConfirm',{attrs:{"data-testid":"shuttle-list__modal-confirm","visible":_vm.isModalConfirmVisible,"title":"Yakin Hapus Data Shuttle?","description":("Data Shuttle " + _vm.deletedShuttleName + " akan hilang"),"actionBtnLabel":"Ya, Hapus","cancelBtnLabel":"Cek Ulang"},on:{"cancel":function () {
        _vm.isModalConfirmVisible = false
        _vm.deletedShuttleName = ''
      },"action":_vm.onDeleteShuttle}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }