export enum SortFields {
  SHUTTLE_ID = 'shuttle_id',
  SHUTTLE_NAME = 'shuttle_name',
  SHUTTLE_AREA = 'shuttle_region',
  PIC_NAME = 'pic_name',
  PIC_PHONE_NUMBER = 'pic_phone_number',
}

export enum SortType {
  SHUTTLE_ID_ASC = 'shuttle_id-asc',
  SHUTTLE_ID_DESC = 'shuttle_id-desc',
  SHUTTLE_NAME_ASC = 'shuttle_name-asc',
  SHUTTLE_NAME_DESC = 'shuttle_name-desc',
  SHUTTLE_AREA_ASC = 'shuttle_region-asc',
  SHUTTLE_AREA_DESC = 'shuttle_region-desc',
  PIC_NAME_ASC = 'pic_name-asc',
  PIC_NAME_DESC = 'pic_name-desc',
  PIC_PHONE_NUMBER_ASC = 'pic_phone_number-asc',
  PIC_PHONE_NUMBER_DESC = 'pic_phone_number-desc',
}
